import { IonButton, IonIcon } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./SportButton.scss";
import { connectSportButton } from "./SportButton.connect";
import ReactGA from "react-ga4";
import { useDispatch } from "react-redux";
import { setUrlForSportbook } from "../../../../store/betbyWidget/urlForSportbook.slice";
import { setSlotCasinoPopUp } from "../../../../store/slotCasinoPopUp/slotCasinoPopUp.slice";
import { requestLiveCasinoID } from "../../../../store/liveCasino/liveCasino.thunks";
import { setQuickButtonSlots } from "../../../../store/slotsGames/slotsGames.slice";
import { setLoginNew } from "../../../../store/LoginNew/loginNew.slice";

const SportButton = ({
                       itemButton: { name, icon, redirect, backgroundImage, backgroundColour, id },
                       auth,
                       actions,
                       elementsParams,
                       menu
                     }) => {
  const history = useHistory();

  const dispatch = useDispatch()

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth;

  const gotoEvent = () => {

    if (redirect.split('=')[1]) {
        dispatch(setUrlForSportbook(redirect.split('=')[1]))
    } else {
        dispatch(setUrlForSportbook(null))
    }

    if (name.indexOf('Evolution') != -1){
      dispatch(setSlotCasinoPopUp(true))
      dispatch(requestLiveCasinoID(redirect))
    } else if (redirect.indexOf('slotsgames') != -1) {
      history.push("/slotsgames-lobby")
      dispatch(setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1)))
      dispatch(setSlotCasinoPopUp(true))
    } else if (redirect.indexOf('livecasino') != -1) {
      history.push("/livecasino-lobby")
      dispatch(setSlotCasinoPopUp(true))
      } else {
      history.push(redirect)
      }


    if(!auth && name.indexOf('Evolution') != -1) {
        dispatch(setLoginNew(true))
    } else if (redirect.indexOf('slotsgames') != -1) {
      history.push("/slotsgames-lobby")
      dispatch(setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1)))
    } else if (redirect.indexOf('livecasino') != -1) {
      history.push("/livecasino-lobby")
      dispatch(setQuickButtonSlots(redirect.slice(redirect.lastIndexOf('/') + 1)))
    } else {
      history.push(redirect)
    }
}

  return (
    <>
      <IonButton className="sport-button" mode="md" id={id}>
        <div className="sport-button__content" onClick={() => {
          ReactGA.event({
            label: "Sports-Button",
            category: "User-Click",
            action: name + "_Sports_Button"
          });
          gotoEvent();

        }
        } style={{ backgroundColor: `${backgroundColour}` }}>
          <img className="sport-button__background-img" src={backgroundImage} alt="" />
          <div className={`sport-button__content-text`}>
            <img src={icon} alt="" />
            <span>{name}</span>
          </div>
        </div>
      </IonButton>
    </>

  );
};

export default connectSportButton()(SportButton);
