import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './PopularGamesGridList.scss';
import { IonList, IonListHeader } from "@ionic/react";
import EventTypeBanners from '../EventTypeBanners/EventTypeBanners';
import { headerType } from '../../helpers/InPlayPopular.helper';
import { requestSlotsGamesID } from '../../store/slotsGames/slotsGames.thunks';
import { setIsPopUpOpen } from '../../store/slotsPopUp/slotsPopUp.slice';
import { connectSlotsGamesLobby } from '../../pages/SlotsGamesLobby/SlotsGamesLobby.connect';
import JiliPopUp from '../SlotsJiliPopUp';
import { useHistory } from 'react-router';

const PopularGamesGridList = ({ itemHeaderData, itemsData, type, actions, elementsParams }) => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const slider = useRef(null);
    const [isDown, setIsDown] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [startX, setStartX] = useState("");
    const [scrollLeft, setScrollLeft] = useState("");
    const [jiliGame, setJiliGame] = useState();
    const isPopUpOpen = useSelector((state) => state.slotsPopUp.isPopUpOpen);
    const [scrollPosition, setScrollPosition] = useState(0);
    const width = elementsParams?.clientWidth || window.innerWidth;
    const imageWidth = width < 1024 ? 212 : 242;
    const history = useHistory();

    const handelSeeMore = () => {
        history.push('/slotsgames-lobby');
    }

    const onOpenGame = useCallback((id, item) => {
        if (auth) {
            if (id.indexOf('jili') !== -1) {
                console.log('open');
                setJiliGame(item);
                dispatch(setIsPopUpOpen(true));
            } else {
                dispatch(requestSlotsGamesID(id));
                history.push(`/live_casino_slot/${id}`);
            }
        } else {
            actions.setLoginNew(true);
        }
    }, [auth, dispatch, history, actions]);

    const scrollTo = useCallback((position) => {
        slider.current.scrollTo({ left: position, behavior: 'smooth' });
        setScrollPosition(position);
    }, []);

    const scrollToPreviousImage = useCallback(() => {
        const previousImageStart = scrollPosition - imageWidth;
        const previousImageIndex = Math.floor(previousImageStart / imageWidth);
        const scrollToPosition = previousImageIndex * imageWidth;
        scrollTo(scrollToPosition);
    }, [scrollPosition, scrollTo]);

    const scrollToNextImage = useCallback(() => {
        const nextImageStart = scrollPosition + imageWidth;
        const nextImageIndex = Math.floor(nextImageStart / imageWidth);
        const scrollToPosition = nextImageIndex * imageWidth;
        scrollTo(scrollToPosition);
    }, [scrollPosition, scrollTo]);

    const getBanner = useMemo(() => {
        if (headerType['INPLAY'] === type) {
            return <EventTypeBanners eventType={itemHeaderData?.id} />;
        }
        return null;
    }, [type, itemHeaderData]);

    const renderHeader = useMemo(() => {
        return (
            <IonListHeader className="inplay-item-list-exchange__header">
                <div className="inplay-item-list-exchange__header-content">
                    <div style={{ padding: `7px` }} className="inplay-item-list-exchange__header-logo">
                        <i className={`inplay-content__logo-icon inplay-content__logo-icon--exchange-games inplay-color-icon`} />
                        {itemHeaderData?.name}
                    </div>
                </div>
                <div className='inplay-item-list-exchange__header_scrollButtons'>
                    <div
                        className={`inplay-item-list-exchange__header_scrollButtons-left ${!scrollPosition && 'disabled'}`}
                        onClick={() => {

                            if (scrollPosition) {
                                scrollToPreviousImage();
                            }
                        }}
                    >
                        &#8592;
                    </div>
                    <div className='inplay-item-list-exchange__header_scrollButtons-divider'></div>
                    <div
                        className={`inplay-item-list-exchange__header_scrollButtons-left ${slider.current?.scrollLeft >= (((itemsData.length / 2) * imageWidth - 12) - slider.current?.offsetWidth) && 'disabled'}`}
                        onClick={() => {
                            if (slider.current?.scrollLeft < (((itemsData.length / 2) * imageWidth - 12) - slider.current?.offsetWidth)) {
                                scrollToNextImage();
                            }
                        }}
                    >
                        &#8594;
                    </div>
                </div>
            </IonListHeader>
        );
    }, [itemHeaderData, scrollPosition, imageWidth, itemsData, scrollToPreviousImage, scrollToNextImage]);

    return (


        <IonList className="inplay-item-list_popularGames">


            {(isPopUpOpen && itemHeaderData.id === 3333333) ? <JiliPopUp item={jiliGame} /> : null}
            {/*{isPopUpOpen ? <JiliPopUp item={jiliGame} /> : null}*/}
            {getBanner}
            {itemHeaderData ? renderHeader : null}

            <div className="popularGames-content"
                ref={slider}
                onMouseDown={(e) => {
                    setIsDown(true);
                    setStartX(e.pageX - slider.current.offsetLeft);
                    setScrollLeft(slider.current.scrollLeft);
                }}
                onMouseUp={() => {
                    if (isSlide) {
                        setTimeout(() => setIsSlide(false), 500);
                    }
                    setIsDown(false);
                }}
                onMouseMove={(e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    setIsSlide(true);
                    setScrollLeft(slider.current.scrollLeft);
                    const x = e.pageX - slider.current.offsetLeft;
                    const walk = (x - startX) * 3; //scroll-fast
                    slider.current.scrollLeft = scrollLeft - walk;
                }}
                onMouseLeave={() => {
                    setScrollLeft(slider.current.scrollLeft);
                    setScrollPosition(slider.current.scrollLeft);
                    setIsDown(false);
                    setIsSlide(false);
                }}
                onTouchEnd={() => {
                    setScrollPosition(slider.current.scrollLeft);
                }}
            >
                {itemsData && itemsData.map((item, index) => {
                    return (

                        item?.ImageURL && <div className="popularGames-item"
                            id={item.ID} key={index}
                            style={{ backgroundColor: `${item?.backgroundColour}` }}
                            onClick={isSlide ? () => { } : () => onOpenGame(item.ID, item)}>
                            {/* <div className="popularGames-img-body" style={{ backgroundImage: `url(${item?.ImageURL || itemHeaderData?.banner})`}}> */}
                            <div className="popularGames-img-body">
                                <img className='popularGames-img-body-image' src={`${item?.ImageURL || itemHeaderData?.banner}`} alt="" />
                                {/*<span className="popularGames-item-name">{item?.en.toLowerCase().split(" ").map(word => word.replace(word[0], word[0].toUpperCase())).join(" ")}</span>*/}
                            </div>
                        </div>
                    )
                })}
                {<div
                    style={{
                        height: '100%',
                        width: '100%',
                        position: "relative"
                    }}
                >
                    <button onClick={handelSeeMore}
                        style={{
                            position: "absolute",
                            top: "86%",
                            left: "50px",
                            width: "calc(100% - 100px)",
                            height: "50px",
                            backgroundColor: "#e1b514",
                            color: "black",
                            borderRadius: "4px",
                        }}
                    >See More</button></div>}
            </div>
        </IonList>

    )
}
export default connectSlotsGamesLobby()(React.memo(PopularGamesGridList));
// export default
