import httpAuth from '../../services/httpAuth'
import httpNotAuth from '../../services/httpNotAuth'
import getErrorMessage from '../../helpers/getErrorMessage.helper'
import { getSlotsGamesSuccess, getSlotsGamesError, onlySlots, mainGames, allSlots, fishingGames, bingoGames, multiplayerGames, virtualGames, scratchGames, instantGames, getSlotsGamesIDSuccess, getSlotsGamesIDError, getSlotsGamesButtonsSuccess, } from './slotsGames.slice'
import axios from 'axios'
import { BASE_DEV_URL } from '../../config/api.config'

export const requestSlotsGames = () => async (dispatch, getState) => {
    const checkAuth = getState().auth
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`casino/v2/slotgames-lobby`)
           if (response.data.success) {
            dispatch(mainGames(response.data.result))
            dispatch(getSlotsGamesButtonsSuccess(response.data.result))
        }
        return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`casino/v2/slotgames-lobby`)
           if (response.data.success) {
            dispatch(mainGames(response.data.result))
            dispatch(getSlotsGamesButtonsSuccess(response.data.result))
        }
        return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}

export const requestOnlySlotsGames = () => async (dispatch, getState) => {
    const checkAuth = getState().auth
    // if(checkAuth) {
        try {
            const response = await axios.post(`https://devweb.khelojito.fun/casino/casino_games/casino_games`, {"casino_cat_ids":["51","52","53","54","55","56","57"]})
           if (response.data) {
               
               const categorizedItems = {};
               
               response.data.data.map(item => {
                   item.Categories.map((category, index) => {
                       console.log(Number(category) > 50, category, typeof +category);
                       if (!categorizedItems[category] && Number(category) > 50) {
                           categorizedItems[category] = {
                            id: category,
                            name: 
                                category == 51 ? 'All Slots' 
                                : category == 52 ? 'Fishing Games'
                                : category == 54 ? 'Multiplayer'
                                : category == 53 ? 'Bingo'
                                : category == 55 ? 'virtual sports'
                                : category == 56 ? 'Scratch Cards'
                                : category == 57 ? 'Instant Game'
                                : '',
                            games: []
                           };
                        }
                        if ( Number(category) > 50) {
                            categorizedItems[category].games.push(item);
                        }
                    });
                });
                
                dispatch(onlySlots(categorizedItems))
            console.log('***********', categorizedItems);

        }
        return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    // } else {
    //     try {
    //         const response = await axios.post(`https://devweb.khelojito.fun/casino/casino_games/casino_games`)
    //        if (response.data) {
    //         dispatch(onlySlots(response.data.data.filter(slot => Number(slot.Categories?.[0]) > 50)))
    //         // dispatch(getSlotsGamesButtonsSuccess(response.data.result))
    //     }
    //     return response
    //     } catch (e) {
    //         dispatch(getSlotsGamesError())
    //         // const errorMsg = getErrorMessage(e)
    //         // throw errorMsg
    //     }
    // }
}

export const requestSlotsGamesLobby = (id) => async (dispatch, getState) => {
    const checkAuth = getState().auth
    
    if(checkAuth) {
        try {
            const response = await httpAuth.get(`/casino/v2/slot-games/${id}`)
           if (response.data.success) {
               if(id === '51'){
                dispatch(allSlots(response.data.result))
               } else if (id === '52'){
                dispatch(fishingGames(response.data.result))
               } else if (id === '53'){
                dispatch(bingoGames(response.data.result))
               } else if (id === '54'){
                dispatch(multiplayerGames(response.data.result))
               } else if (id === '55'){
                dispatch(virtualGames(response.data.result))
               } else if (id === '56'){
                dispatch(scratchGames(response.data.result))
               } else if (id === '57'){
                dispatch(instantGames(response.data.result))
               }
            }
            return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    } else {
        try {
            const response = await httpNotAuth.get(`/casino/v2/slot-games/${id}`)
           if (response.data.success) {
               if(id === '51'){
                dispatch(allSlots(response.data.result))
               } else if (id === '52'){
                dispatch(fishingGames(response.data.result))
               } else if (id === '53'){
                dispatch(bingoGames(response.data.result))
               } else if (id === '54'){
                dispatch(multiplayerGames(response.data.result))
               } else if (id === '55'){
                dispatch(virtualGames(response.data.result))
               } else if (id === '56'){
                dispatch(scratchGames(response.data.result))
               } else if (id === '57'){
                dispatch(instantGames(response.data.result))
               }
            }
            return response
        } catch (e) {
            dispatch(getSlotsGamesError())
            // const errorMsg = getErrorMessage(e)
            // throw errorMsg
        }
    }
}

export const requestSlotsGamesID = (id) => async (dispatch) => {
    const token = localStorage.getItem('id_token')
    try {
        const response = await axios.get(BASE_DEV_URL + `/casino/live/table-w/${id}`, {
            headers: {
              Authorization: `${token}`,
            },
          })
       if (response.data.success) {
        // window.open(response?.data?.result?.url)

        dispatch(getSlotsGamesIDSuccess(response.data.result))
    }
    return response
    } catch (e) {
        dispatch(getSlotsGamesIDError())
        const errorMsg = getErrorMessage(e)
        throw errorMsg
    }
}

export const requestJILIGamesID = (id) => async (dispatch) => {
  const token = localStorage.getItem('id_token')
  try {
    const response = await axios.get(BASE_DEV_URL + `/casino/live/table/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    })
    if (response.data.success) {
    //   window.open(response?.data?.result?.url)
      dispatch(getSlotsGamesIDSuccess(response.data.result))
    }
    return response
  } catch (e) {
    dispatch(getSlotsGamesIDError())
    const errorMsg = getErrorMessage(e)
    throw errorMsg
  }
}
