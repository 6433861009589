import AllSlots from '../assets/images/leftSideMenuIcons/allSlots.svg';
import Fishing from '../assets/images/leftSideMenuIcons/fishing.svg';
import Multiplayer from '../assets/images/leftSideMenuIcons/multiplayer.svg';
import Bingo from '../assets/images/leftSideMenuIcons/bingo.svg';
import Virtual from '../assets/images/leftSideMenuIcons/virtual.svg';
import Scratch from '../assets/images/leftSideMenuIcons/scratch.svg';
import Instant from '../assets/images/leftSideMenuIcons/instant.svg';




const leftSideMenuMapper = {
  'All Slots': {
    id: 51,
    img: AllSlots,
    name: 'All Slots',
    isSvg: true,
  },

  'Fishing Games': {
    id: 52,
    name: 'Fishing Games',
    img: Fishing,
    isSvg: true,
  },

  Multiplayer: {
    id: 54,
    name: 'Multiplayer',
    img: Multiplayer,
    isSvg: true,
  },

  Bingo: {
    id: 53,
    name: 'Bingo',
    img: Bingo,
    isSvg: true,
  },

  'Virtual Sports': {
    id: 55,
    name: 'Virtual Sports',
    img: Virtual,
    isSvg: true,
  },

  'Scratch Cards': {
    id: 56,
    name: 'Scratch Cards',
    img: Scratch,
    isSvg: true,
  },
  'Instant Games': {
    id: 57,
    name: 'Instant Games',
    img: Instant,
    isSvg: true,
  },
  
}

export default leftSideMenuMapper
