import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setLoginNew } from '../../store/LoginNew/loginNew.slice'
import { setSlotCasinoPopUp } from '../../store/slotCasinoPopUp/slotCasinoPopUp.slice'
import { requestSlotsGames, requestSlotsGamesLobby, requestOnlySlotsGames } from '../../store/slotsGames/slotsGames.thunks'
import { setQuickButtonSlots } from '../../store/slotsGames/slotsGames.slice'

export const connectLeftSideMenu = (wrappedProps) => (component) => {
  function mapStateToProps(state) {
    return {
      menu: state.menu,
      auth: state.auth,
      version: state.version,
      slotsGames: state.slotsGames,
      quickButtonSlots: state.slotsGames.quickButtonSlots,

    }
  }

  function mapDispatchToProps(dispatch) {
    const actions = {
      setLoginNew,
      setSlotCasinoPopUp,
      requestOnlySlotsGames,
      requestSlotsGames,
      requestSlotsGamesLobby,
      setQuickButtonSlots
    }

    return { actions: bindActionCreators(actions, dispatch) }
  }

  return connect(mapStateToProps, mapDispatchToProps)(component)
}
