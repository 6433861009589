import "./BettingProfitAndLossDetailsTable.scss";
import React from "react";
import {tsToDate} from "../../helpers/date.helper";
import { getCurrencyFormatWithZeroAfterDot } from "../../helpers/getCurrencyFormat.helper";

const BettingProfitAndLossDetailsTable = ({itemDetails, selectedItemDetails, timeSettings, setSelectedItemDetails, breadcrumbNames}) => {

  const roundId = selectedItemDetails && selectedItemDetails?.marketId.split(".")[1];

    const getBackSubtotal = () => {
        if (itemDetails.length <= 0) return 0;

        let sum = 0;
        itemDetails.forEach(item => {
            if (item.side === 0) {
                sum += (item?.profitLoss || 0);
            }
        });
        return sum;
    };

    const getLaySubtotal = () => {
        if (itemDetails.length <= 0) return 0;

        let sum = 0;
        itemDetails.forEach(item => {
            if (item.side === 1) {
                sum += (item?.profitLoss || 0);
            }
        });
        return sum;
    };

    const getNetMarketTotal = () => {
        if (itemDetails.length <= 0) return 0;

        return (getBackSubtotal() + getLaySubtotal()) - (selectedItemDetails?.memberComm || 0);
    };

    return (
      <div className="bettingProfitAndLossDetailsTable">
        <p className="bettingProfitAndLossDetailsTable__table-breadcrumbs">
          <span onClick={() => { setSelectedItemDetails(null);}} className="text-bold">{breadcrumbNames?.buttonName}</span>
          <span> `{'>'}` {itemDetails[0]?.eventName} - {itemDetails[0]?.marketName}</span>
        </p>
        <div className="bettingProfitAndLossDetailsTable__table-inner">
          <ul className="bettingProfitAndLossDetailsTable__table-header">
            <li>Placed</li>
            <li>Selection</li>
            <li>Bet ID</li>
            <li>Type</li>
            <li>Odds</li>
            <li>Stake</li>
            <li className="info-right">Profit/Loss</li>
            <li className="info-right">Status</li>
          </ul>
          <div className="bettingProfitAndLossDetailsTable__table-body">
            {itemDetails.length >= 1 && itemDetails.map((item, index) =>
              <ul className="bettingProfitAndLossDetailsTable__table-body-row" key={item?.lastSettledDate + index}>
                <li>
                  <span className="text-bold">{tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName)?.split(" ")[0].split("/").map((dateItem) => dateItem.length === 1 ? "0" + dateItem : dateItem).join("/")}
                </span>
                  <span className="text-color">| {tsToDate(item?.betPlacedDate, timeSettings?.timeZoneName).split(" ")[1]}</span>
                </li>
                <li>{item?.selectionName}</li>
                <li>{item?.apolloBetId}</li>
                <li>{item?.side === 0 ? "Back" : "Lay"}</li>
                <li>{item?.averagePrice.toFixed(3)}</li>
                <li>{getCurrencyFormatWithZeroAfterDot(item?.size)}</li>
                <li className={`info-right text-bold ${item?.profitLoss >= 0 ? "green" : "red"}`}>{getCurrencyFormatWithZeroAfterDot(item?.profitLoss)}</li>
                <li className={`info-right text-bold ${item?.outcome === "WON" ? "green" : "red"}`}>{item?.outcome}</li>
              </ul>,
            )}
          </div>
        </div>
        <div className="bettingProfitAndLossDetailsTable__info-wrapper">
          <ul className="bettingProfitAndLossDetailsTable__info">
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Back subtotal:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price ${getBackSubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal())}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Lay subtotal</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price ${getLaySubtotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                 {getCurrencyFormatWithZeroAfterDot(getLaySubtotal())}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Market subtotal:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price ${((getBackSubtotal() + getLaySubtotal()).toFixed(2)) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getBackSubtotal() + getLaySubtotal())}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Commission:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price`}>
                {getCurrencyFormatWithZeroAfterDot(selectedItemDetails?.memberComm) || 0}
              </span>
            </li>
            <li>
              <span className="bettingProfitAndLossDetailsTable__info-title">Net Market Total:</span>
              <span className={`bettingProfitAndLossDetailsTable__info-price bettingProfitAndLossDetailsTable__info-price-with-border ${getNetMarketTotal().toFixed(2) < 0 ? "negative-value" : ""}`}>
                {getCurrencyFormatWithZeroAfterDot(getNetMarketTotal())}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
};

export default BettingProfitAndLossDetailsTable;
