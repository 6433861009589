import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  IonContent,
  IonPage,
  IonHeader, useIonViewWillLeave, useIonViewWillEnter, useIonViewDidEnter, useIonViewDidLeave,
} from '@ionic/react'

import Toolbar from '../../components/Toolbar/Toolbar'
import useScript from '../../hooks'
import { CONFIG } from '../../config/api.config'
import { randomBytes } from 'crypto'
import { useHistory } from 'react-router'
import { setLoginNew, setTab } from '../../store/LoginNew/loginNew.slice'
import { setUrlForSportbook } from '../../store/betbyWidget/urlForSportbook.slice'

function isNotEmptyObject(obj) {
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      return true
    }
  }
  return false
}

const SportbookNotAuth = () => {
  var history = useHistory()
  const dispatch = useDispatch()

  const urlSportbook = useSelector((state) => state.urlSportbook)

  const [isInitiated, setIsInitiated] = useState(false);

  // useScript('https://tanktech.sptpub.com/bt-renderer.min.js')

  useIonViewWillEnter(() => {
    try {
      // if (bt !== undefined)
      //   bt.kill()
      // if (!isInitiated)
        // initSportsbook(0)
      console.log('entered the home 1')
    } catch (e) {
      console.log('error captured in home 1')
    }
  })

  useIonViewWillLeave(() => {
    console.log('Leaving the view');
    dispatch(setUrlForSportbook(null));

    try {
      if(window.BTrendererVar && Object.keys(window.BTrendererVar).length>0){
        window.BTrendererVar.kill();
      }
    } catch (error) {
      console.error('Error while killing BTrendererVar:', error);
    } finally {
      // Update the URL without re-rendering
      try {
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
        window.BTrendererVar.kill();

      } catch (error) {
        console.error('Error while updating URL:', error);
    }
    }
  });

  useEffect(() => {
    initSportsbook(0)

  }, [urlSportbook]);

  const initSportsbook = (i = 0) => {
    if (!history.location.pathname.includes('/sportsbook')) {
        return null; // Return null to unmount the component
      }
    console.log('Iteration ' + i)
    if (i < 10) {
      try {
        setIsInitiated(true);
        console.log(window.BTrendererVar,"--old BTrendererVar object")
        if(window.BTrendererVar && Object.keys(window.BTrendererVar).length>0){
          console.log("killing first")
          window.BTrendererVar.kill();
          window.BTrendererVar={};
          console.log(window.BTrendererVar,"--old after kill BTrendererVar object")

        }
        window.BTrendererVar = new window.BTRenderer().initialize({
          brand_id: '1928062748156104704',
          key: null,
          themeName: CONFIG.betbyThemeName,
          lang: 'en',
          target: document.getElementById('betby-na'),
          url: urlSportbook ? `${urlSportbook}` : null,
          // minFrameHeight: 700,
          // betSlipOffsetTop: 0,
          // betslipZIndex: 999,
          cssUrls: [
            'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
          ],
          fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],

          onRouteChange: function() {
            console.log('Route changed')
          },
          // callback to recharge
          onRecharge: () => {
            console.log('Recharge changed')
          },
          // callback to sign in
          onLogin: () => {
            dispatch(setLoginNew(true));
            console.log('Login changed')
          },
          // callback to sign up
          onRegister: () => {
            dispatch(setLoginNew(true))
            dispatch(setTab(2))
            console.log('Signup changed')
          },
          onSessionRefresh: () => {
            window.BTrendererVar.kill()
            history.push('/default-page')
            // initSportsbook();
          },
          // callback to change url when user clicks on event link
          goToClick: args => {
            console.log('Click changed')
          },

          widgetParams: {
            // placeholder name from backoffice
            // placeholder: "placeholder_name",
            // authentication is needed for this widget
            // withAuthentication: false
          },
        })
        console.log(window.BTrendererVar,"--BTrendererVar object")

      } catch (e) {
        console.log(e)
        setTimeout(() => initSportsbook(i + 1), 300)
      }
    } else {
      console.log('initSportsbook error')
    }

    return window.BTrendererVar
  }

  useEffect(() => {
    // if (!isInitiated)
      initSportsbook()
  }, [history.location])

  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  return (
    <IonPage className="CasinoGames CasinoGames-sportsbook not-auth">
      {width < 1024 ? (
        <IonHeader>
          <Toolbar />
        </IonHeader>
      ) : (
        <div style={{ height: '135px' }}></div>
      )
      }
      <IonContent fullscreen>
        <div id="betby-na">
        </div>
      </IonContent>
    </IonPage>
  )
}

export default SportbookNotAuth
