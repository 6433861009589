import livecasino from '../assets/images/menu-icons/livecasino-icon.svg'
import sportsbook from '../assets/images/menu-icons/sportsbook-icon.svg'
import blastoff from '../assets/images/menu-icons/blastoff-icon.svg'
import election from '../assets/images/menu-icons/election-icon.svg'
import bannerСasino from '../assets/images/banners/sport-banner-casino.png'
import bannerExchange from '../assets/images/banners/sport-banner-exchange.png'
import bannerHorse from '../assets/images/banners/sport-banner-horse.svg'
import bannerGreyhound from '../assets/images/banners/sport-banner-greyhound.svg'



const popularGamesMapper = {
    'My Markets': {
        img: '',
      },
    
      Cricket: {
        id: 4,
        name: 'Cricket',
        img: 'ꀃ',
      },
    
      Football: {
        id: 1,
        name: 'Football',
        img: "ꀄ",
      },
    
      Tennis: {
        id: 2,
        name: 'Tennis',
        img: "ꀅ",
      },
    
      Election: {
        id: 2378962,
        name: 'Election',
        img: election,
        isSvg: true,
      },
    
      'Indian Card Games': {
        id: 1444001,
        name: 'Indian Card Games',
        img: '',
        banner: bannerExchange,
        bannerClass: 'exchange',
        bannerLink: '/menu-event/1444001',
      },
      'Popular Games': {
        id: 1111111,
        name: 'Popular Games',
        img: livecasino,

      },
      'Casino': {
        id: 1444000,
        name: 'Casino',
        img: livecasino,
        isSvg: true,
      },
    
      'Casino Live': {
        name: 'Casino',
        id: 77777,
        img: "",
        banner: bannerСasino,
        bannerClass: 'casino',
        bannerLink: '/casino_live/1444000',
      },
      'Jili Games': {
        name: 'Jili Games',
        id: 3333333,
        img: ""
      },
      'New Games': {
        name: 'New Games',
        id: 2222222,
        img: ""
      },


      'Horse Racing': {
        id: 7,
        name: 'Horse Racing',
        img: "ꀂ",
        banner: bannerHorse,
        bannerClass: 'greyhound',
        bannerLink: '/menu-event/7',
      },
    
      'Greyhound Racing': {
        id: 4339,
        name: 'Greyhound Racing',
        img: "ꀁ",
        banner: bannerGreyhound,
        bannerClass: 'horse',
        bannerLink: '/menu-event/4339',
      },
    
      Sportsbook: {
        id: 1444005,
        name: 'Sportsbook (80+)',
        img: sportsbook,
        isSvg: true,
      },
    
      'BlastOff': {
        name: 'Blastoff',
        id: 67722,
        img: blastoff,
        bannerLink: '/casino_live/67722/FAWK',
        isSvg: true
      },
}

export default popularGamesMapper
