import './inplay.scss'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IonItemGroup } from '@ionic/react'
import popularGamesMapper from '../../helpers/getPopularGamesMapper.helper'
import { useHistory } from 'react-router'
import PopularGamesGridList from '../PopularGamesGridList/PopularGamesGridList'

const InPlayPopular = ({
  inPlayEvents,
  balanceSettings,
  timeSettings,
  type,
  displayedBack,
  setDisplayedBack,
  betPlacing,
  orderCount,
  pathname,
  promotions,
}) => {
  const [inPlayEventsMap, setInPlayEventsMap] = useState(new Map())
  console.log("🚀 ~ file: inPlayPopular.js:22 ~ inPlayEventsMap:",  Object.entries(inPlayEventsMap))
  const history = useHistory()
  const auth = useSelector((state) => state.auth)

  const getListsItems = () => {
    return Object.entries(popularGamesMapper)
      .filter(([key, value]) => !!inPlayEventsMap.get(value.id))
      .map(([key, value]) => {
        console.log('first', value)

        switch (value.id) {
          // case 77777:
          //   return (
          //     history.location.pathname !== '/favouritesPage' && (
          //       <div key={value.id}>
          //         {/* {auth ? (
          //           <BetbyWidget pathname={pathname} />
          //         ) : (
          //            <BetbyWidgetNotAuth startBetByWidget={startBetByWidget} pathname={pathname} />
          //         )} */}
          //         <CasinoGridList
          //           key={value.id}
          //           type={type}
          //           itemHeaderData={value}
          //           itemsData={inPlayEventsMap.get(value.id)}
          //         />
          //       </div>
          //     )
          //   )

          // case 1444001:
          //   return history.location.pathname !== '/favouritesPage' ? (
          //     <ExchangeGamesGridList
          //       key={value.id}
          //       type={type}
          //       itemHeaderData={value}
          //       itemsData={inPlayEventsMap.get(value.id)}
          //     />
          //   ) : (
          //     <InPlayPopularItemList
          //       type={type}
          //       timeSettings={timeSettings}
          //       betPlacing={betPlacing}
          //       key={value.id}
          //       displayedBack={displayedBack}
          //       setDisplayedBack={setDisplayedBack}
          //       itemHeaderData={value}
          //       balanceSetting={balanceSettings && balanceSettings[value.id]}
          //       itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
          //     />
          //   )
          case 1111111:

          console.log('first', value)

            return (<PopularGamesGridList
                key={value.id}
                type={type}
                itemHeaderData={value}
                itemsData={inPlayEventsMap.get(value.id)}
              />)
              case 2222222:
                    console.log('first', value)
            return (<PopularGamesGridList
                key={value.id}
                type={type}
                itemHeaderData={value}
                itemsData={inPlayEventsMap.get(value.id)}
            />)
          case 3333333:

            console.log('first', value)

                return (<PopularGamesGridList
                    key={value.id}
                    type={type}
                    itemHeaderData={value}
                    itemsData={inPlayEventsMap.get(value.id)}
                  />)
          default:
            return <></>
              
              // <InPlayPopularItemList
              //   type={type}
              //   timeSettings={timeSettings}
              //   betPlacing={betPlacing}
              //   key={value.id}
              //   displayedBack={displayedBack}
              //   setDisplayedBack={setDisplayedBack}
              //   itemHeaderData={value}
              //   balanceSetting={balanceSettings && balanceSettings[value.id]}
              //   itemsData={inPlayEventsMap.get(value.id).sort(a => a?.event?.name?.includes("SRL") ? 1 : -1).sort(a => a?.market?.id?.includes("BR") ? 1 : -1)}
              // />
            
        }
      })
  }

  const updateInPlayEventsMap = () => {
    // if (!inPlayEvents) {
    //   return
    // }

    inPlayEventsMap.clear()
    // inPlayEvents.forEach((e) => {
    //   const evId = Number(e.eventTypeId)
    //   const value = inPlayEventsMap.get(evId)
    //   if (value) {
    //     value.push(e)
    //   } else {
    //     inPlayEventsMap.set(evId, [e])
    //   }
    // })
    if (promotions && promotions?.popularBanners) {
      promotions.popularBanners.forEach((e) => {
        const evId = 1111111;
        const value = inPlayEventsMap.get(evId)
        if (value) {
          value.push(e)
        } else {
          inPlayEventsMap.set(evId, [e])
        }
      })
    }
    if (promotions && promotions?.['popularBanners-2']) {
      promotions['popularBanners-2'].forEach((e) => {
        const evId = 2222222;
        const value = inPlayEventsMap.get(evId)
        if (value) {
          value.push(e)
        } else {
          inPlayEventsMap.set(evId, [e])
        }
      })
    }
    if (promotions && promotions?.['popularBanners-3']) {
      promotions['popularBanners-3'].forEach((e) => {
        const evId = 3333333;
        const value = inPlayEventsMap.get(evId)
        if (value) {
          value.push(e)
        } else {
          inPlayEventsMap.set(evId, [e])
        }
      })
    }

    setInPlayEventsMap(inPlayEventsMap)
  }

  useEffect(() => {
    updateInPlayEventsMap()
  })

  return (
    <IonItemGroup className="inplay-content">
      {/* <InPlayPopularHeader type={type} orderCount={orderCount} /> */}

      {getListsItems()}
    </IonItemGroup>
  )
}

export default InPlayPopular
